import React, { useState } from "react";
import KALogo from "../KALogo";
import { useDispatch, useSelector } from "react-redux";
import { setIsRagCollapsed } from "../../../../../redux/slices/appSlice";
import usePostMessage from "../../../../../utils/controllers/usePostMessage";
import { getColorOpacityPercentage } from "../../../../../utils/controllers/colors";
import CollapseIcon from "../CollapseIcon";
import InliFAQInputForm from "./InliFAQInputForm";
import Icon from "../../../../../utils/components/Icon";

function InlineFAQTopBar({
  handleGenerateAnswer,
  responseData,
  inputQuery,
  setInputQuery,
  isLoading,
}) {
  const primaryColor = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.primaryColor
  );
  const injectionStyle = useSelector(
    (state) => state.flipcard.data.config.injectionStyle
  );
  const [isAlreadyCollapsed, setIsAlreadyCollapsed] = useState(false);
  const { postMessage } = usePostMessage();
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };
  const dispatch = useDispatch();
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);

  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  const handleQuestionClick = () => {
    setIsAlreadyCollapsed(true);
    if (isRagCollapsed) {
      dispatch(setIsRagCollapsed(!isRagCollapsed));
      postMessage({
        event: "toggleCollapsion",
        trackerId: getQueryParam("trackerId"),
      });
      if (!isAlreadyCollapsed) {
        handleGenerateAnswer(questions[0] || "", { isAlreadyExpanded: true });
      }
    }
  };

  const handleClick = () => {
    dispatch(setIsRagCollapsed(!isRagCollapsed));
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  const isInline = injectionStyle === "inline";

  const getIconName = () => {
    if (isInline) {
      return isRagCollapsed ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line";
    }
    return isRagCollapsed ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line";
  };

  return (
    <div
      className="kaTopBarOne"
      style={{
        cursor: isRagCollapsed && questions?.length > 0 && "pointer",
      }}
      onClick={() => {
        if (questions?.length > 0) {
          handleQuestionClick();
        }
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 10,
        }}
      >
        <KALogo />
        {isRagCollapsed ? (
          <>
            {questions?.length > 0 ? (
              <>
                {responseData?.length > 0 ? (
                  <div className="kaCollapseIcon">
                    <Icon name={getIconName()} />
                  </div>
                ) : (
                  <div>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: 400,
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                        opacity: 0.3,
                      }}
                      className="kaInlineTopBarTopText"
                    >
                      Explore Answers
                      <img
                        style={{ height: "15px" }}
                        src="https://prod-assets-156041422845.s3.eu-west-1.amazonaws.com/images/3abbd4ba-d469-4405-a75f-48039a8a1415.Image"
                      />
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                {responseData?.length > 0 && (
                  <div className="kaCollapseIcon">
                    <Icon name={getIconName()} onClick={handleClick} />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <CollapseIcon responseData={responseData} />
        )}
      </div>

      {isRagCollapsed && (
        <>
          {questions?.length > 0 ? (
            <div
              style={{
                backgroundColor: getColorOpacityPercentage(primaryColor, 0.07),
                padding: "var(--mpr-3)",
                borderRadius: "0px var(--mpr-mini) var(--mpr-mini) 0px",
                borderLeft: "3px solid var(--primary-Color)",
                color: primaryColor,
              }}
            >
              <h3 style={{ fontSize: "0.9rem" }}>{questions[0] || ""}</h3>
            </div>
          ) : (
            <InliFAQInputForm
              handleGenerateAnswer={handleGenerateAnswer}
              inputQuery={inputQuery}
              setInputQuery={setInputQuery}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </div>
  );
}

export default InlineFAQTopBar;
