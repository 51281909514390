import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const environment = api.getState()?.flipcard?.data?.config?.environment;
  const sessionId = api.getState()?.flipcard?.data?.config?.sessionId;
  const mode = api.getState()?.flipcard?.data?.config?.mode;

  if (mode === "preview") {
    return { data: { success: true, data: {} } };
  }

  let baseUrl;

  switch (environment) {
    case "staging":
      baseUrl = "https://stg-gateway.bridged.media";
      break;
    case "production":
      baseUrl = "https://gateway.bridged.media";
      break;
    case "local":
      baseUrl = "http://localhost:9001";
      break;
    default:
      baseUrl = "https://gateway.bridged.media";
  }

  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    headers: { sessionId: sessionId },
  });
  return rawBaseQuery(args, api, extraOptions);
};

export const bridgedApi = createApi({
  reducerPath: "bridgedApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    engagementTake: builder.mutation({
      query: (body) => ({
        url: "/Engagement/Take",
        method: "POST",
        body,
      }),
    }),
    engagementView: builder.mutation({
      query: (body) => ({
        url: "/Engagement/View",
        method: "POST",
        body,
      }),
    }),
    ctaTake: builder.mutation({
      query: (body) => ({
        url: "/Action/Take",
        method: "POST",
        body,
      }),
    }),
    ctaView: builder.mutation({
      query: (body) => ({
        url: "/Action/View",
        method: "POST",
        body,
      }),
    }),
    endScreenView: builder.mutation({
      query: (body) => ({
        url: "/endscreen/view",
        method: "POST",
        body,
      }),
    }),
    shareButtonClicked: builder.mutation({
      query: (body) => ({
        url: "/endscreen/ShareButtonClicked",
        method: "POST",
        body,
      }),
    }),
    sendError: builder.mutation({
      query: (body) => ({
        url: "/Logging/Flipcard",
        method: "POST",
        body,
      }),
    }),
    collectEvent: builder.mutation({
      query: (body) => ({
        url: "/Session/Collect",
        method: "POST",
        body,
      }),
    }),
    userFeedback: builder.mutation({
      query: (body) => ({
        url: "/Campaign/SubmitFeedback",
        method: "POST",
        body,
      }),
    }),
    submitRagQuery: builder.mutation({
      query: (body) => ({
        url: "/Campaign/SubmitRAGQuery",
        method: "POST",
        body,
      }),
    }),
    submitDmg: builder.mutation({
      query: (body) => ({
        url: "https://dmg-demo-service-80245669623.europe-west1.run.app/generateInsights",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useEngagementTakeMutation,
  useEngagementViewMutation,
  useCtaTakeMutation,
  useCtaViewMutation,
  useEndScreenViewMutation,
  useShareButtonClickedMutation,
  useSendErrorMutation,
  useCollectEventMutation,
  useUserFeedbackMutation,
  useSubmitRagQueryMutation,
  useSubmitDmgMutation,
} = bridgedApi;
