import "../styles/multimedia.css";
import React, { useState } from "react";
import Story from "react-insta-stories";
import {
  ImageContainer,
  SeeMoreContainer,
  SeeMoreRedirectLink,
  VideoContainer,
} from "../components/MultimediaContainer";
import CtaFlipIcon from "../../../../utils/components/CtaFlipIcon";
import { useSelector } from "react-redux";
import CtaBackButton from "../../../../utils/components/CtaBackButton";

function MultimediaWorkflow({ data }) {
  const isGatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const [isAllStoryFinished, setIsAllStoryFinished] = useState(false);
  const convertDataToStories = (data) => {
    const stories = data.map((item) => {
      const { url, type, seeMore } = item;
      const { isSeeMore, redirectLink } = seeMore;

      const commonSeeMoreProps =
        isSeeMore && redirectLink
          ? {
              seeMoreCollapsed: () => (
                <SeeMoreRedirectLink linkSrc={redirectLink} />
              ),
              seeMore: ({ close }) => <SeeMoreContainer close={close} />,
            }
          : {};

      if (type === "image") {
        return {
          content: (props) => <ImageContainer {...props} src={url} />,
          ...commonSeeMoreProps,
        };
      } else if (type === "video") {
        return {
          content: (props) => <VideoContainer {...props} src={url} />,
          ...commonSeeMoreProps,
        };
      }

      return null;
    });

    return stories.filter((story) => story !== null);
  };

  return (
    <div className="multimediaContainer">
      {isAllStoryFinished && (
        <>
          {isGatedEngagement ? (
            <CtaBackButton isCloseBtn={true} />
          ) : (
            <CtaFlipIcon color="black" />
          )}
        </>
      )}

      <Story
        preloadCount={3}
        keyboardNavigation
        defaultInterval={8000}
        stories={convertDataToStories(data?.actionData?.assets)}
        width="100%"
        height="100%"
        onAllStoriesEnd={() => setIsAllStoryFinished(true)}
      />
    </div>
  );
}

export default MultimediaWorkflow;
