import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsFlipped } from "../../../redux/slices/appSlice";

function isEmpty(obj) {
  return Object.keys(obj)?.length === 0;
}

const useFlipcardController = () => {
  const dispatch = useDispatch();
  const flipcard = useSelector((state) => state.flipcard);

  const engagementType = useSelector(
    (state) =>
      state?.flipcard?.data?.flipCard?.engagement?.engagementData
        ?.engagementType
  );

  useEffect(() => {
    if (!isEmpty(flipcard.data)) {
      const handleFlip = () => {
        if (engagementType) {
          dispatch(setIsFlipped(false));
        } else {
          dispatch(setIsFlipped(true));
        }
      };

      handleFlip();
    }
  }, [engagementType, flipcard]);

  return {};
};

export default useFlipcardController;
