import React, { useEffect, useRef } from "react";
import DmgLogoTitle from "./DmgLogoTitle";
import DmgInputBox from "./DmgInputBox";
import DmgResponse from "./DmgResponse";
import Divider from "../../../../utils/components/Divider";

function DmgBody({
  inputQuery,
  setInputQuery,
  isLoading,
  onHandleDmgClick,
  responseData,
  handleQueryDownload,
  handleAnswerPdfDownload,
}) {
  const scrollableBodyRef = useRef(null);

  // Scroll to the top when responseData changes
  useEffect(() => {
    if (scrollableBodyRef.current) {
      scrollableBodyRef.current.scrollTo({
        top: 0,
        behavior: "smooth", // Optional for smooth scrolling
      });
    }
  }, [responseData]);

  return (
    <div className="dmgBody">
      <div className="dmgTopBar">
        <DmgLogoTitle isLoading={isLoading} />
        <DmgInputBox
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          isLoading={isLoading}
          onHandleDmgClick={onHandleDmgClick}
        />
      </div>

      <div className="DmgScrollableDiv customScrollBar" ref={scrollableBodyRef}>
        {responseData?.map((item, index) => {
          return (
            <div key={index} style={{ width: "100%" }}>
              <DmgResponse
                data={item}
                onHandleDmgClick={onHandleDmgClick}
                handleQueryDownload={handleQueryDownload}
                handleAnswerPdfDownload={handleAnswerPdfDownload}
                index={index}
              />
              {index !== responseData.length - 1 && (
                <Divider style={{ margin: "var(--mpr-1-2) 0px" }} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DmgBody;
