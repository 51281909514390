import React from "react";
import CollapseIcon from "../CollapseIcon";
import KALogo from "../KALogo";
import { useSelector } from "react-redux";
import OneInput from "./OneInput";
import KALoader from "../KALoader";

function OneTopBar({
  responseData,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
  isLoading,
  error,
}) {
  const title = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.title
  );

  return (
    <div className="kaTopBarOne">
      <KALogo />
      <CollapseIcon responseData={responseData} />
      <h3>{title || "Ask a question related to article."}</h3>
      <KALoader
        isLoading={isLoading}
        responseData={responseData}
        error={error}
      />
      <OneInput
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
        responseData={responseData}
      />
    </div>
  );
}

export default OneTopBar;
