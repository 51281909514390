import React from "react";
import CtaFlipIcon from "../../../../utils/components/CtaFlipIcon";
import { useSelector } from "react-redux";
import Divider from "../../../../utils/components/Divider";
import Button from "../../../../utils/components/Button";

function Content({ handleToggleDisplay, data, logo }) {
  const { title, description, actionData } = data;

  const signUp_TEXT = useSelector((state) => state.language.data.signUp_TEXT);
  const textColor = useSelector((state) => state.app.ctaTextColor);

  return (
    <div className="ctaMainContent">
      <CtaFlipIcon color={textColor} />

      <div
        className="ctaContent"
        style={{
          color: textColor,
        }}
      >
        {logo && <img src={logo} alt="Logo" />}

        <h3>{title}</h3>
        <Divider type="primary" />
        <p
          style={{
            backgroundColor: textColor === "white" ? "#00000080" : "#ffffff80",
          }}
          className="description"
        >
          {description}
        </p>

        <Button
          onClick={() => {
            handleToggleDisplay(true);
          }}
        >
          {signUp_TEXT}
        </Button>
      </div>
    </div>
  );
}

export default Content;
