import React from "react";
import Divider from "../../../../utils/components/Divider";
import CtaFlipIcon from "../../../../utils/components/CtaFlipIcon";
import { useSelector } from "react-redux";
import useLinkController from "../controllers/useLinkController";
import Button from "../../../../utils/components/Button";

function Content({ data, logo }) {
  const textColor = useSelector((state) => state.app.ctaTextColor);
  const readMore_TEXT = useSelector(
    (state) => state.language.data.readMore_TEXT
  );
  const { handleClickLink, isLoading } = useLinkController();

  const { title, description, actionData } = data;

  const { buttonText, buttonLink } = actionData;

  const submitData = () => {
    handleClickLink({
      actionId: data?._id,
      takeActionData: {
        actionType: "link",
      },
    });
  };

  return (
    <div className="ctaMainContent">
      <CtaFlipIcon color={textColor} />

      <div
        className="ctaContent"
        style={{
          color: textColor,
        }}
      >
        {logo && <img src={logo} alt="Logo" />}
        <h3>{title}</h3>
        <Divider type="primary" />
        <p
          style={{
            backgroundColor: textColor === "white" ? "#00000080" : "#ffffff80",
          }}
          className="description"
        >
          {description}
        </p>

        <Button href={buttonLink} onClick={submitData} isLoading={isLoading}>
          {buttonText || readMore_TEXT}
        </Button>
      </div>
    </div>
  );
}

export default Content;
