import React from "react";
import "../styles/sliderPoll.css";
import TopBar from "../../reusable/components/TopBar";
import Body from "../components/Body";

function SliderPollWorkflow() {
  return (
    <div className="sliderPoll">
      <TopBar />
      <Body />
    </div>
  );
}

export default SliderPollWorkflow;
