import React from "react";
import useGetWindowWidth from "../../../../../utils/controllers/useGetWindowWidth";
import InlineDesktop from "./desktop/InlineDesktop";
import InlineMobile from "./mobile/InlineMobile";

function Inline() {
  const width = useGetWindowWidth();

  if (width >= 500) {
    return <InlineDesktop />;
  }

  return <InlineMobile />;
}

export default Inline;
