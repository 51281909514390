import React, { useCallback } from "react";
import useGetWindowWidth from "../../../../utils/controllers/useGetWindowWidth";
import Icon from "../../../../utils/components/Icon";
import { useSelector } from "react-redux";
import Divider from "../../../../utils/components/Divider";
import Button from "../../../../utils/components/Button";
import Input from "../../../../utils/components/Input";
import useDynamicFormController from "../controllers/useDynamicFormController";

function Body({ toggleDisplay, handleToggleDisplay, data: userData }) {
  const fields = userData?.actionData?.fields;
  const {
    handleSubmitForm,
    handleInputChange,
    formState,
    formErrors,
    isLoading,
  } = useDynamicFormController(userData);

  const language = useSelector((state) => state.language.data);
  const { back_TEXT, signUp_TEXT } = language;
  const width = useGetWindowWidth();

  const handleBackButtonClick = useCallback(() => {
    handleToggleDisplay(false);
  }, [handleToggleDisplay]);

  return (
    <div
      className="formsBody customScrollBar"
      style={{
        display: width > 500 || toggleDisplay ? "flex" : "none",
      }}
    >
      <div className="formsBodyTop">
        <div className="topArrow">
          <span onClick={handleBackButtonClick}>
            <Icon name="ri-arrow-left-s-line" />
            {back_TEXT}
          </span>
          <Divider style={{ margin: "var(--mpr-3) 0px" }} />
        </div>

        <form>
          {fields.map((field, index) => {
            let type = field?.type;

            if (type === "phone") {
              type = "number";
            }
            return (
              <React.Fragment key={index}>
                <h5>
                  {field.title} {field.optional ? "" : <span>*</span>}
                </h5>

                <Input
                  type={type}
                  id={field._id}
                  onChange={handleInputChange}
                  isRequired={!field.optional}
                  value={formState[field._id] || ""}
                  error={formErrors[field._id]}
                  maxLength={field?.maxLength}
                  placeholder={field?.hint}
                  maxLine={field.maxLine}
                />
              </React.Fragment>
            );
          })}
        </form>
      </div>
      <div className="formsBodyBottom">
        <Divider />
        <Button isLoading={isLoading} onClick={handleSubmitForm}>
          {"Download Now"}
        </Button>
      </div>
    </div>
  );
}

export default Body;
