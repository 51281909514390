import React from "react";
import TitleLeftBorder from "../../../../utils/components/TitleLeftBorder";
import { useSelector } from "react-redux";
import FlipIcon from "../../../components/FlipIcon";

function TopBar() {
  const poweredByText = useSelector(
    (state) => state.language.data.poweredBy_TEXT
  );

  const engagementId = useSelector(
    (state) => state.flipcard?.data?.flipCard?.engagement?._id
  );

  const actionPage = useSelector(
    (state) => state.flipcard.data.flipCard.actionPage
  );

  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);

  const publisherLogo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );

  const title = actionPage?.title;
  const description = actionPage?.description;

  return (
    <div className="ctaCarouselTopBar">
      <div className="ctaCarouselTopbarDiv">
        <div className="ctaCarouselTopbarLogoDiv">
          {!publisherLogo && <p>{poweredByText}</p>}
          {publisherLogo && (
            <img
              onClick={() => {
                if (!publisherLogo) {
                  window.open("https://www.bridged.media/", "_blank");
                }
              }}
              src={publisherLogo}
              alt="Publisher Logo"
            />
          )}
          {!publisherLogo && bridgedLogo && (
            <img src={bridgedLogo} alt="Bridged Logo" />
          )}
        </div>
        {engagementId && <FlipIcon />}
      </div>
      <div className="relative">
        <TitleLeftBorder left="-20px">
          <div className="ctaCarouselTitleAndDesc">
            <h3>{title}</h3>
            {description && <p>{description}</p>}
          </div>
        </TitleLeftBorder>
      </div>
    </div>
  );
}

export default TopBar;
