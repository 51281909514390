import React from "react";
import OneFAQ from "./OneFAQ";
import KAFooter from "../KAFooter";
import { useSelector } from "react-redux";
import KABody from "../KABody/KABody";

function OneBody({
  responseData,
  handleGenerateAnswer,
  handeLinkClick,
  handRatingTake,
}) {
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);

  if (!isRagCollapsed) {
    return (
      <>
        <OneFAQ
          responseDataLength={responseData?.length}
          handleGenerateAnswer={handleGenerateAnswer}
        />
        <KABody
          responseData={responseData}
          handeLinkClick={handeLinkClick}
          handRatingTake={handRatingTake}
        />
        <KAFooter />
      </>
    );
  }

  return <></>;
}

export default OneBody;
