import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";

export const exportDmgPdf = (data) => {
  const { data_table, insights, question } = data;
  const doc = new jsPDF();

  // Reduce the font size
  doc.setFontSize(10);

  // Add the question with reduced spacing
  doc.setFont("helvetica", "bold");
  doc.text("Question:", 10, 10);
  doc.setFont("helvetica", "normal");
  doc.text(question, 10, 15); // Reduced space between "Question" and the actual question

  // Add dynamic insights with bold titles, bullet points for insights and recommendations, and improved spacing
  let linePosition = 25;
  insights.forEach(({ title, insight, recommendation }, index) => {
    doc.setFont("helvetica", "bold");
    // Use index + 1 to prepend the title number
    doc.text(`Recommendation ${index + 1}: ${title}`, 10, linePosition);
    linePosition += 6; // Reduced space after title

    doc.setFont("helvetica", "normal");

    // Add bullet point before insight
    let wrappedInsight = doc.splitTextToSize(`• ${insight}`, 180); // Wrap text if too long
    doc.text(wrappedInsight, 10, linePosition);
    linePosition += wrappedInsight.length * 5; // Adjusted spacing based on text length

    // Add bullet point before recommendation
    let wrappedRecommendation = doc.splitTextToSize(`• ${recommendation}`, 180);
    doc.text(wrappedRecommendation, 10, linePosition);
    linePosition += wrappedRecommendation.length * 5 + 6; // Reduced space after recommendation
  });

  // Extract columns and rows from data_table
  const columns = data_table.columns.map((col) => col.title);
  const rows = data_table.tableData.map((row) =>
    data_table.columns.map((col) => row[col.dataIndex])
  );

  // Add the table dynamically with proper column width and adjusted font size
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: linePosition + 5, // Slightly reduced margin between insights and table
    styles: { fontSize: 9 }, // Smaller font size for table content
    margin: { right: 10, left: 10 }, // Ensure margins are applied
  });

  // Save the PDF
  doc.save("dynamic_data.pdf");
};
