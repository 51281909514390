import React from "react";
import "../styles/poll.css";
import TopBar from "../../reusable/components/TopBar";
import Body from "../components/Body";
import { useSelector } from "react-redux";

function PollWorkflow() {
  const disclaimer = useSelector(
    (state) => state.flipcard.data.config.disclaimer
  );

  return (
    <div className="poll">
      <TopBar />
      <Body />
      {disclaimer && (
        <div className="pollDisclaimer">
          <h5>{disclaimer}</h5>
        </div>
      )}
    </div>
  );
}

export default PollWorkflow;
