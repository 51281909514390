import React, { useEffect, useRef, useState } from "react";
import ScrollAnimation from "../../../../../utils/components/scrollAnimation/ScrollAnimation";
import KARatings from "./KARatings";
import KATutors from "./KATutors";
import KAAnswerChatBot from "./KAAnswerChatBot";

function KABodySidebarThree({
  responseData,
  handeLinkClick,
  handRatingTake,
  isLoading,
  inputQuery,
  shouldLastQuestionBlur,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const scrollableBodyRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      function handleScroll() {
        if (scrollableBodyRef.current) {
          const { scrollHeight, scrollTop, clientHeight } =
            scrollableBodyRef.current;
          const isScrollable = scrollHeight > clientHeight;
          const isAtBottom = scrollTop + clientHeight >= scrollHeight - 15; // Adjusted for the 20px safe zone

          if (isScrollable) {
            setIsVisible(!isAtBottom);
          } else {
            setIsVisible(false);
          }
        }
      }

      handleScroll();

      if (scrollableBodyRef.current) {
        scrollableBodyRef.current.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (scrollableBodyRef.current) {
          scrollableBodyRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }, 500);
  }, [scrollableBodyRef, responseData]);

  useEffect(() => {
    if (scrollableBodyRef.current) {
      if (isLoading) {
        scrollableBodyRef.current.scrollTo({
          top: scrollableBodyRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [isLoading]);

  const extendedData = isLoading
    ? [...responseData, { question: inputQuery, isLoading }]
    : responseData;

  return (
    <div
      style={{ overflow: "scroll" }}
      className="kaBody customScrollBar"
      ref={scrollableBodyRef}
    >
      {extendedData.map((data, index) => {
        const isLastIndex = extendedData?.length - 1 === index;

        return (
          <div key={index}>
            <KAAnswerChatBot
              data={data}
              responseAnswer={data?.response}
              handeLinkClick={handeLinkClick}
              isBlur={shouldLastQuestionBlur && isLastIndex}
            />
            <KATutors data={data?.tutors} />
            <KARatings
              data={data?.feedback}
              handRatingTake={handRatingTake}
              index={index}
            />
          </div>
        );
      })}

      <ScrollAnimation
        isVisible={isVisible}
        style={{ left: "90%", bottom: "130px" }}
      />
    </div>
  );
}

export default KABodySidebarThree;
