import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCtaViewHandler from "../../../ctas/reusable/controllers/useCtaViewHandler";
import useLinkController from "../../../ctas/link/controllers/useLinkController";
import Icon from "../../../../utils/components/Icon";

function KAGatedInline({ currentAction, setIsVisible }) {
  const { handleCtaView } = useCtaViewHandler();
  const { handleClickLink } = useLinkController();
  const readMore_TEXT = useSelector(
    (state) => state.language.data.readMore_TEXT
  );

  const gatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const background = currentAction?.background;
  const type = background?.type;
  const imageURL = background?.imageURL;
  const isImage = type === "image";

  return (
    <div className="kaGatedInlineCard">
      <div>{isImage && <img src={imageURL} alt="Background" />}</div>
      <div className="kaGatedInlineCardContent">
        <div
          style={{
            height: currentAction?.actionData?.buttonLink ? "auto" : "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: currentAction?.actionData?.buttonLink ? "0px" : "3px",
          }}
        >
          <h4 className="thirdLine">{currentAction?.title}</h4>
          <p>{currentAction?.description}</p>
        </div>
        {currentAction?.actionData?.buttonLink && (
          <p
            className="primaryTextColor"
            style={{ fontWeight: 500, cursor: "pointer" }}
            onClick={() => {
              handleCtaView(currentAction?._id);
              handleClickLink({
                actionId: currentAction?._id,
                takeActionData: {
                  actionType: "link",
                },
              });
              window.open(currentAction?.actionData?.buttonLink, "_blank");
            }}
          >
            {currentAction?.actionData?.buttonText || readMore_TEXT}
          </p>
        )}
      </div>
      {!gatedEngagement ? (
        <div
          className="kaGatedInlineCardCloseIcon"
          onClick={() => setIsVisible(false)} // Hide component on click
          style={{ cursor: "pointer" }}
        >
          <Icon name="ri-close-line" />
        </div>
      ) : null}
    </div>
  );
}

export default KAGatedInline;
