import React from "react";
import Loader from "../presentation/loader/workflow/Loader";
import { useSelector } from "react-redux";
import getTextColor from "../controllers/getTextColor";

function Button({ children, onClick, isLoading, href, loadingColor }) {
  const primaryColor = useSelector(
    (state) => state.flipcard.data.flipCard.theme.primaryColor
  );

  const textColor = getTextColor(primaryColor) || "white";

  return (
    <button
      onClick={() => {
        if (href) {
          window.open(href, "_blank");
        }
        if (onClick) {
          onClick();
        }
      }}
      disabled={isLoading}
      style={{
        opacity: isLoading && 0.7,
        color: textColor,
      }}
    >
      {!isLoading && children}
      {isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader style={{ borderBottomColor: loadingColor || textColor }} />
        </div>
      )}
    </button>
  );
}

export default Button;
