import { useDispatch, useSelector } from "react-redux";
import { useCtaTakeMutation } from "../../../../services/api";
import {
  setIsCtaTaken,
  setIsShowEndScreen,
} from "../../../../redux/slices/appSlice";
import useMessage from "../../../../utils/presentation/message/controllers/useMessage";
import { useState } from "react";

function convertObjectToArray(inputObject) {
  const outputArray = [];
  for (const key in inputObject) {
    if (Object.hasOwnProperty.call(inputObject, key)) {
      outputArray.push({ _id: key, value: inputObject[key] });
    }
  }
  return outputArray;
}

function useDynamicFormController(userData) {
  const fields = userData?.actionData?.fields;
  const isEndScreen = useSelector(
    (state) => state.flipcard?.data?.flipCard?.endScreen?._id
  );
  const [formErrors, setFormErrors] = useState({});
  const [formState, setFormState] = useState({});
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);

  const { showMessage } = useMessage();
  const dispatch = useDispatch();
  const [_CTA_TAKE, { isLoading }] = useCtaTakeMutation();

  const handleInputChange = (e) => {
    const fieldId = e.target.id;
    if (formErrors[fieldId]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldId]: undefined,
      }));
    }

    const { id, value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    fields.forEach((field) => {
      const { _id, optional, type, title } = field;
      if (!optional && !formState[_id]) {
        newErrors[_id] = `This ${title} is required.`;
      }

      if (
        type === "email" &&
        formState[_id] &&
        !emailRegex.test(formState[_id].trim())
      ) {
        newErrors[_id] = "Please enter a valid email address.";
      }
    });

    setFormErrors(newErrors);

    return Object.keys(newErrors)?.length === 0;
  };

  const handleEndScreen = () => {
    if (isEndScreen) {
      dispatch(setIsShowEndScreen(true));
    }
  };

  const handleSubmitForm = async () => {
    if (mode === "create") {
      return;
    }

    let sentData = convertObjectToArray(formState);

    if (validateForm()) {
      const response = await _CTA_TAKE({
        actionId: userData?._id,
        takeActionData: {
          actionType: "dynamicForm",
          fields: sentData,
        },
      });

      if (response?.data?.success) {
        dispatch(setIsCtaTaken(true));
        handleEndScreen();
      }

      if (response?.error) {
        showMessage("error", response?.error?.data?.errorObject?.userErrorText);
      }
    }
  };

  return {
    handleSubmitForm,
    handleInputChange,
    formState,
    formErrors,
    isLoading,
  };
}

export default useDynamicFormController;
