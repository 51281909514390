import React from "react";
import { useSelector } from "react-redux";

function Footer() {
  const footer = useSelector((state) => state.flipcard.data.config?.footer);

  return (
    <div className="dmgFullPageFooter">
      {footer?.isActive && (
        <p
          dangerouslySetInnerHTML={{
            __html: footer?.title,
          }}
        />
      )}
    </div>
  );
}

export default Footer;
