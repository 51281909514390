// Import necessary dependencies and components
import React from "react";
import FlipcardLayout from "../../../layout/FlipcardLayout";
import useCommunication from "../../../utils/controllers/useCommunication";
import useFlipcardController from "../controllers/useFlipcardController";
import Flipcard from "../components/Flipcard";

// Define the FlipcardWorkflow component
function FlipcardWorkflow() {
  // Custom hooks to manage communication and flipcard functionality
  const { isGateOpen } = useCommunication();
  useFlipcardController();

  // Function to determine which section to render based on isGateOpen
  const renderSection = () => {
    switch (isGateOpen) {
      case false:
        return <Flipcard />;
      default:
        return <div />; // Return empty div if gate is open (can be replaced with appropriate component)
    }
  };

  // Render FlipcardWorkflow component
  return (
    <FlipcardLayout>
      {renderSection()} {/* Render the determined section */}
    </FlipcardLayout>
  );
}

// Export the FlipcardWorkflow component
export default FlipcardWorkflow;
