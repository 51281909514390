import React from "react";
import "../styles/quiz.css";
import TopBar from "../../reusable/components/TopBar";
import Body from "../components/Body";

function QuizWorkflow() {
  return (
    <div className="poll">
      <TopBar />
      <Body />
    </div>
  );
}

export default QuizWorkflow;
