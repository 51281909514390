import React from "react";
import CtaWorkflow from "../../../ctas/workflow/CtaWorkflow";
import { useSelector } from "react-redux";

function KAGatedAgent() {
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const kaGatedTrigger = useSelector((state) => state.app.kaGatedTrigger);

  if (isCtaTaken) {
    return <></>;
  }

  if (kaGatedTrigger) {
    return (
      <div className="gatedCtaOverlay">
        <CtaWorkflow actionIndex={0} isTransparent={true} />
      </div>
    );
  }

  return <></>;
}

export default KAGatedAgent;
