import React from "react";
import { useSelector } from "react-redux";

function OneFAQ({ handleGenerateAnswer, responseDataLength }) {
  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  const suggestedQuestions = useSelector(
    (state) => state.language.data.suggestedQuestions
  );

  if (questions?.length && responseDataLength === 0) {
    return (
      <div className="kaFaqOneParent">
        <div className="kaFaqOne">
          <h4>{suggestedQuestions}</h4>

          <ul>
            {questions?.map((data, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setTimeout(() => {
                      handleGenerateAnswer(data);
                    }, 200);
                  }}
                >
                  {data}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  return <></>;
}

export default OneFAQ;
