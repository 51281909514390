import React, { useEffect, useState } from "react";
import { Tiny } from "@ant-design/charts";
import { useDispatch, useSelector } from "react-redux";
import useEngController from "../../reusable/controllers/useEngController";
import { setIsShowEngResult } from "../../../../redux/slices/appSlice";
import { getColorOpacityPercentage } from "../../../../utils/controllers/colors";

function Body() {
  const swipeTheMarker = useSelector(
    (state) => state.language.data.swipeTheMarker
  );
  const isFlipped = useSelector((state) => state.app.isFlipped);
  const [sliderValue, setSliderValue] = useState(4);
  const dispatch = useDispatch();
  const primaryColor = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.primaryColor
  );

  const { handleEngTake, selectedId } = useEngController();
  const engagement = useSelector(
    (state) => state.flipcard.data.flipCard.engagement
  );

  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const isShowEngResult = useSelector((state) => state.app.isShowEngResult);
  const gatedEngagementConfig = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const options = engagement?.engagementData?.options?.map((item, index) => {
    let defaultId = index + 1;
    let _id = item?._id || defaultId.toString();
    return {
      ...item,
      _id,
    };
  });

  const optionsData = engagement?.optionsData?.map((item, index) => {
    let defaultId = index + 1;
    let optionId =
      item?.optionId ||
      engagement?.engagementData?.options[index]?._id ||
      defaultId.toString();

    return {
      ...item,
      optionId,
    };
  });

  const handleOptionClicked = (optionId) => {
    if (!selectedId) {
      dispatch(setIsShowEngResult(!gatedEngagementConfig));
      handleEngTake(optionId);
    }
  };

  useEffect(() => {
    dispatch(setIsShowEngResult(isCtaTaken));
  }, [isCtaTaken]);

  const chartData = optionsData.map((data) => {
    let count = data.takesCount;
    if (data?.optionId === selectedId) {
      count = count + 1;
    }
    return {
      optionId: data.optionId,
      count,
    };
  });

  const config = {
    data: chartData,
    xField: "optionId",
    yField: "count",
    smooth: true,
    height: 200,
    padding: 0,
    margin: 0,
    shapeField: "smooth",
    style: {
      fill: `linear-gradient(-90deg, ${getColorOpacityPercentage(
        primaryColor,
        0.5
      )} 0%, ${primaryColor} 100%)`,
    },
    animate: { enter: { type: "scaleInY" } },
    axis: {
      x: {
        label: false,
        grid: false,
      },
      y: {
        label: false,
        grid: false,
      },
    },
  };

  return (
    <div className="engInputMainDiv">
      {!isFlipped && (
        <div className="sliderPollChartParent">
          {isShowEngResult ? (
            <div className="sliderPollChart">
              <Tiny.Area {...config} />
            </div>
          ) : (
            <div className="sliderPollMarkerDiv">
              <p>{swipeTheMarker}</p>
            </div>
          )}
        </div>
      )}
      <div className="sliderPollSlider">
        <span></span>
        <input
          type="range"
          className="slider"
          min="0"
          max="8"
          value={sliderValue}
          disabled={selectedId}
          onMouseUpCapture={() => {
            handleOptionClicked(options[sliderValue]?._id);
          }}
          onTouchEnd={() => {
            handleOptionClicked(options[sliderValue]?._id);
          }}
          onChange={(e) => {
            setSliderValue(e.target.value);
          }}
        />
      </div>
      <div className="sliderPollFooter">
        <p>{options[0]?.title}</p>
        <p className="sliderPollSecondOption">
          {options[options?.length - 1]?.title}
        </p>
      </div>
    </div>
  );
}

export default Body;
