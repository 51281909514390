import React, { useEffect, useRef } from "react";
import "../../styles/default.css";
import XRayTopBar from "./XRayTopBar";
import CurrentXrayWorkflow from "../../workflow/CurrentXrayWorkflow";
import MoreQuestions from "../MoreQuestions";
import CtaWorkflow from "../../../../ctas/workflow/CtaWorkflow";
import useXrayDefault from "../../controllers/useXrayDefault";
import { useSelector } from "react-redux";
import GoogleAdsWorkflow from "../../../../common/googleAds/workflow/GoogleAdsWorkflow";
import Refinery89Ads from "../../../../common/googleAds/components/Refinery89Ads";
import FeedbackTwo from "../../../reusable/components/feedbacks/FeedbackTwo";
import PoweredBy from "../../../reusable/components/poweredBy/PoweredBy";

function Default() {
  const injectionStyle = useSelector(
    (state) => state.flipcard.data.config.injectionStyle
  );

  const darkTheme = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme
  );

  const showGoogleAd = useSelector(
    (state) => state.flipcard.data?.config?.googleAdConfig?.isActive
  );

  const showRefineryAdConfig = useSelector(
    (state) => state.flipcard.data?.config?.refineryAdConfig?.isActive
  );

  const {
    engagementData,
    isLinkCta,
    remainingData,
    ctaIndex,
    handlePopEngagementData,
    handleAddEngagementData,
    isShowCta,
  } = useXrayDefault();

  const xrayScrollableBodyRef = useRef(null);

  useEffect(() => {
    if (xrayScrollableBodyRef.current) {
      xrayScrollableBodyRef.current.scrollTop = 0;
    }
  }, [engagementData]);

  return (
    <div className="xray">
      <XRayTopBar
        currentEngData={engagementData[engagementData?.length - 1]}
        totalEngagements={engagementData?.length}
        handlePopEngagementData={handlePopEngagementData}
        isShowCta={isShowCta()}
      />

      <div
        ref={xrayScrollableBodyRef}
        className={` ${
          injectionStyle !== "inline" && "xrayScrollableBody customScrollBar"
        } ${isShowCta() && "xrayBgBlur"}`}
        style={{
          backgroundColor: darkTheme ? "#2E2E2E" : "#F5F5F5",
          marginTop: "2px",
        }}
      >
        <CurrentXrayWorkflow
          currentEngData={engagementData[engagementData?.length - 1]}
          isPadding={true}
          isTwoTheme={true}
        />

        {!isLinkCta && (
          <MoreQuestions
            remainingData={remainingData}
            handleAddEngagementData={handleAddEngagementData}
            isTwoTheme={true}
          />
        )}

        <div style={{ padding: "0px var(--mpr-1-2)" }}>
          <FeedbackTwo isShowCta={isShowCta()} />
        </div>

        {showGoogleAd && (
          <div
            style={{
              marginTop: "var(--mpr-2)",
            }}
            className={`${isShowCta() && "xrayBgBlur"} googleAdsParent`}
          >
            <GoogleAdsWorkflow />
          </div>
        )}
      </div>

      {showRefineryAdConfig && <Refinery89Ads />}

      {isShowCta() && (
        <div className="gatedCtaOverlay xrayOverlay xrayOverlayTwo">
          <CtaWorkflow actionIndex={ctaIndex} isTransparent={true} />
        </div>
      )}

      <PoweredBy />
    </div>
  );
}

export default Default;
