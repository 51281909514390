import React from "react";
import DmgTable from "./DmgTable";
import Icon from "../../../../utils/components/Icon";
import Button from "../../../../utils/components/Button";

function getTableData(input) {
  const columns = Object.keys(input).map((key) => ({
    title: key,
    dataIndex: key,
    key: key,
  }));

  const tableData = input[columns[0].dataIndex].map((_, index) => {
    const row = { key: index + 1 };
    columns.forEach((column) => {
      row[column.dataIndex] = input[column.dataIndex][index];
    });
    return row;
  });

  return { columns, tableData };
}

function DmgResponse({
  data,
  onHandleDmgClick,
  handleQueryDownload,
  handleAnswerPdfDownload,
  index,
}) {
  if (data?.success && data?.data?.alternate_queries?.length) {
    return (
      <div className="DmgAlternateQueriesContainer">
        <div>
          <h4 style={{ marginBottom: "var(--mpr-3)" }}>Question:</h4>
          <p>{data?.question}</p>
        </div>
        <div>
          <h4 style={{ marginBottom: "var(--mpr-3)" }}>Reason:</h4>
          <p>{data?.data?.reason}</p>
        </div>
        <div>
          <h4 style={{ marginBottom: "var(--mpr-3)" }}>Alternate Questions:</h4>
          <div className="DmgAlternateQueries">
            {data?.data?.alternate_queries?.map((question, index) => {
              return (
                <p
                  key={index}
                  onClick={() => {
                    onHandleDmgClick(question);
                  }}
                >
                  {question}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  if (data?.success) {
    const { columns, tableData } = getTableData(data?.data?.data_table);

    return (
      <div className="DmgSuccessContainer">
        <div>
          <h4 style={{ marginBottom: "var(--mpr-3)" }}>Question:</h4>
          <p>{data?.question}</p>
        </div>
        <div>
          <DmgTable
            data_table={data?.data?.data_table}
            columns={columns}
            tableData={tableData}
          />
        </div>
        <div>
          <h4 style={{ marginBottom: "var(--mpr-3)" }}>
            Looking to evolve the results?
          </h4>
          {data?.data?.insights?.map((insight, index) => {
            return (
              <ul
                key={index}
                style={{
                  paddingLeft: "var(--mpr-1-2)",
                  marginBottom: "var(--mpr-1-2)",
                }}
              >
                <li>
                  <span>Recommendation {index + 1}: </span> {insight?.title}
                </li>
                <ul
                  style={{
                    paddingLeft: "var(--mpr-1)",
                    display: "grid",
                    gap: "var(--mpr-mini)",
                    marginTop: "var(--mpr-mini)",
                  }}
                >
                  <li>{insight?.insight}</li>
                  <li>{insight?.recommendation}</li>
                </ul>
              </ul>
            );
          })}
        </div>
        <div className="DmgButtonContainer">
          <Button
            onClick={() => {
              handleAnswerPdfDownload({
                ...data?.data,
                question: data?.question,
                data_table: { columns, tableData },
              });
            }}
          >
            Answer <Icon name="ri-download-fill" style={{ fontSize: "15px" }} />
          </Button>
          <Button
            onClick={() => {
              handleQueryDownload(data?.data?.query);
            }}
          >
            Query used{" "}
            <Icon name="ri-download-fill" style={{ fontSize: "15px" }} />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="DmgErrorContainer">
      <div>
        <h4 style={{ marginBottom: "var(--mpr-3)" }}>
          <Icon name="ri-error-warning-line" style={{ fontSize: "20px" }} />{" "}
          Error:
        </h4>
        <p>
          {data?.error_object?.error_message || "Oops something went wrong"}
        </p>
      </div>
    </div>
  );
}

export default DmgResponse;
