import { useEffect } from "react";

const useGoogleAdManagerScript = () => {
  useEffect(() => {
    // Function to create and append the script tag
    const appendScript = (src, async) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = async;
      document.head.appendChild(script);
    };

    // Append the first script
    appendScript("https://securepubads.g.doubleclick.net/tag/js/gpt.js", true);

    // Append the inline script
    const script = document.createElement("script");
    script.innerHTML = `
      window.googletag = window.googletag || {cmd: []};
      googletag.cmd.push(function() {
        googletag.defineSlot('/23173276666/bridged_reader_assistant_1', [[336, 280], [300, 250]], 'div-gpt-ad-1721792486313-0').addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();
      });
    `;
    document.head.appendChild(script);

    return () => {
      // Cleanup: Remove the scripts when the component unmounts
      document.head.removeChild(script);
      const existingScript = document.querySelector(
        `script[src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"]`
      );
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);
};

export default useGoogleAdManagerScript;
