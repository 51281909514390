import { useSelector } from "react-redux";
import useEngController from "../../reusable/controllers/useEngController";
import { useEffect, useState } from "react";

function useXrayDefault() {
  const { handleXraytake } = useEngController();
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const [isLinkCta, setIsLinkCta] = useState(false);
  const [ctaIndex, setCtaIndex] = useState(-1);

  const options = useSelector(
    (state) => state.flipcard.data.flipCard.engagement?.engagementData?.options
  );

  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);

  const [engagementData, setEngagementData] = useState(options[0]);

  const [remainingData, setRemainingData] = useState(
    options.filter((item) => item.type !== options[0]?.type)
  );

  useEffect(() => {
    if (options) {
      const firstItem = options[0];
      setEngagementData([firstItem]);

      const filteredOptions = options.filter((item) => {
        if (item.type === "relatedTopics" || item.type === "relatedAuthors") {
          return item.relatedActions?.length > 0;
        } else {
          return true;
        }
      });

      setRemainingData(
        filteredOptions.filter((item) => item.type !== options[0]?.type)
      );
    }
  }, [options]);

  const handleAddEngagementData = (item) => {
    handleXraytake(item?._id);
    setEngagementData((prevEngagementData) => [...prevEngagementData, item]);
    setRemainingData((prevRemainingData) =>
      prevRemainingData.filter((dataItem) => dataItem !== item)
    );
  };

  const handlePopEngagementData = () => {
    if (engagementData?.length > 1) {
      const lastItem = engagementData[engagementData?.length - 1];
      setEngagementData((prevEngagementData) =>
        prevEngagementData.slice(0, -1)
      );
      setRemainingData((prevRemainingData) => [...prevRemainingData, lastItem]);
    }
  };

  const isShowCta = () => {
    if (isLinkCta || ctaIndex === -1) {
      return false;
    }
    return (
      !isCtaTaken &&
      ctas?.length > 0 &&
      engagementData?.length > 1 &&
      !isXrayCollapsed
    );
  };

  useEffect(() => {
    const currentEngType = engagementData[engagementData?.length - 1]?.type;
    const isBoolean =
      currentEngType === "relatedTopics" || currentEngType === "relatedAuthors";

    setIsLinkCta(isBoolean);
  }, [engagementData]);

  useEffect(() => {
    const index = ctas.findIndex(
      (action) => action.actionData.actionType !== "link"
    );
    if (index >= 0) {
      setCtaIndex(index);
    }
  }, []);

  return {
    engagementData,
    isLinkCta,
    remainingData,
    ctaIndex,
    handlePopEngagementData,
    handleAddEngagementData,
    isShowCta,
  };
}

export default useXrayDefault;
