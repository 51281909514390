import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function KALoader({ isLoading, responseData, error, loaderColor }) {
  const { ragLoaderTexts, answersGenerated_TEXT } = useSelector(
    (state) => state.language.data
  );
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const isDarkTheme = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme
  );

  const [loaderTextIndex, setLoaderTextIndex] = useState(0);

  useEffect(() => {
    if (isLoading) {
      if (loaderTextIndex < ragLoaderTexts?.length - 1) {
        const interval = setInterval(() => {
          setLoaderTextIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex >= ragLoaderTexts?.length - 1) {
              clearInterval(interval);
            }
            return newIndex;
          });
        }, 2000);
        return () => clearInterval(interval);
      }
    } else {
      setLoaderTextIndex(0);
    }
  }, [isLoading, loaderTextIndex]);

  const handleLoaderOpacity = () => {
    if (isLoading) {
      return 1;
    }

    if (isRagCollapsed && responseData?.length > 0) {
      return 1;
    }

    return 0;
  };
  return (
    <div>
      {error?.isError ? (
        <h4
          style={{
            opacity: 1,
            fontWeight: 500,
            color: "red",
          }}
        >
          {error?.message}
        </h4>
      ) : (
        <>
          <h4
            style={{
              opacity: handleLoaderOpacity(),
              fontWeight: 500,
              color: isDarkTheme ? "yellow" : loaderColor || "#395EF9",
            }}
          >
            {!isLoading && (
              <>
                {isRagCollapsed &&
                  responseData?.length > 0 &&
                  ` ${responseData?.length} ${answersGenerated_TEXT}`}
              </>
            )}

            {isLoading ? ragLoaderTexts[loaderTextIndex] : "."}
          </h4>
        </>
      )}
    </div>
  );
}

export default KALoader;
