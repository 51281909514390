import React, { useState } from "react";
import { useSelector } from "react-redux";

function Checkbox({ onChange, isRequired, error, text, ...args }) {
  const required_TEXT = useSelector(
    (state) => state.language.data.required_TEXT
  );
  const [isChecked, setIsChecked] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleCheckboxChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    }

    if (isRequired) {
      setIsValid(e.target.checked);
    }

    setIsChecked(e.target.checked);
  };

  const handleCheckboxFocus = () => {
    if (error) {
      setIsValid(true);
    }
  };

  return (
    <div className={`inputError ${isValid && !error ? "" : "error"}`}>
      {!isValid && !error && <p className="errorText">{required_TEXT}</p>}
      {error && <p className="errorText">{error}</p>}
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          onFocus={handleCheckboxFocus}
          {...args}
        />
        <span
          style={{
            fontSize: "0.9rem",
          }}
        >
          {text}
        </span>
      </label>
    </div>
  );
}

export default Checkbox;
