import React, { useEffect } from "react";

const AdsComponent = ({ dataAdSlot, dataAdClient }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense error: ", e);
    }
  }, []);

  return (
    <>
      {dataAdSlot && dataAdClient ? (
        <ins
          className="adsbygoogle"
          style={{
            display: "block",
            textAlign: "center",
          }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client={dataAdClient}
          data-ad-slot={dataAdSlot}
        ></ins>
      ) : null}
    </>
  );
};

export default AdsComponent;
