import React, { useState } from "react";
import { useSelector } from "react-redux";

function Input({ onChange, isRequired, error, maxLine, ...args }) {
  const required_TEXT = useSelector(
    (state) => state.language.data.required_TEXT
  );
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (e) => {
    onChange(e);

    if (isRequired) {
      const inputValue = e.target.value.trim();
      const isValidInput = inputValue !== "";

      setIsValid(isValidInput);
    }
  };

  const handleInputFocus = () => {
    if (error) {
      setIsValid(true);
    }
  };

  return (
    <div className={`inputError ${isValid && !error ? "" : "error"}`}>
      {!isValid && !error && <p className="errorText">{required_TEXT}</p>}
      {error && <p className="errorText">{error}</p>}

      {args?.type === "text" && maxLine ? (
        <textarea
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          {...args}
          rows={maxLine}
        />
      ) : (
        <input
          style={{
            height: `${38 * maxLine}px`,
          }}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          {...args}
        />
      )}
    </div>
  );
}

export default Input;
