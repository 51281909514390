import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsRagCollapsed,
  setIsRagFirstQuestion,
  setIsRagTwoQuestionAsked,
  setKaGatedTrigger,
  setKaQuestionTrigger,
  setNumberOfKaQuestionsSubmitted,
} from "../../../../redux/slices/appSlice";
import usePostMessage from "../../../../utils/controllers/usePostMessage";
import {
  useCtaTakeMutation,
  useEngagementTakeMutation,
  useSubmitRagQueryMutation,
  useUserFeedbackMutation,
} from "../../../../services/api";
import useGetWindowWidth from "../../../../utils/controllers/useGetWindowWidth";

function extractDomain(url) {
  let domain = url?.replace(/(https?:\/\/)?(www\.)?/, "");
  domain = domain?.split("/")[0];
  return domain;
}

function useKnowledgeAgentController() {
  const isDEAStaticDemo = useSelector(
    (state) => state?.flipcard?.data?.config?.isDEAStaticDemo
  );

  const actionBucketId = useSelector(
    (state) => state?.flipcard?.data?.config?.actionBucketId
  );
  const pageURL = useSelector(
    (state) => state?.flipcard?.data?.config?.pageURL
  );
  const environment = useSelector(
    (state) => state?.flipcard?.data?.config?.environment
  );
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);
  const numberOfQuestionsSubmittedConfig = useSelector(
    (state) => state?.flipcard?.data?.config?.numberOfQuestionsSubmitted
  );
  const showActionAfterResponses = useSelector(
    (state) => state?.flipcard?.data?.config?.showActionAfterResponses
  );
  const gatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );
  const actions = useSelector((state) => state.flipcard.data.flipCard.actions);
  const kaQuestionTrigger = useSelector((state) => state.app.kaQuestionTrigger);
  const numberOfKaQuestionsSubmitted = useSelector(
    (state) => state.app.numberOfKaQuestionsSubmitted
  );
  const [isMobileView, setIsMobileView] = useState(false);
  const engagementTemplate = useSelector(
    (state) =>
      state?.flipcard?.data?.flipCard?.engagement?.engagementData
        ?.engagementTemplate
  );
  const width = useGetWindowWidth();
  const [error, setIsError] = useState({
    isError: false,
    message: "",
  });
  const campaignType = useSelector(
    (state) => state?.flipcard?.data?.config?.campaignType
  );
  const dispatch = useDispatch();
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const isRagTwoQuestionAsked = useSelector(
    (state) => state.app.isRagTwoQuestionAsked
  );
  const engagementId = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?._id
  );

  const options = useSelector(
    (state) => state.flipcard.data.flipCard?.engagement?.engagementData?.options
  );

  const [isLoading, setIsLoading] = useState(false);
  const [inputQuery, setInputQuery] = useState("");
  const [responseData, setResponseData] = useState([]);
  const { postMessage } = usePostMessage();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const [_ENGAGEMENT_TAKE] = useEngagementTakeMutation();
  const [_SEND_RAG_QUERY] = useSubmitRagQueryMutation();

  const handleGenerateAnswer = async (input = inputQuery, extraConfig) => {
    if (!input || isLoading) return;

    setInputQuery(input);
    setIsLoading(true);

    try {
      let response = {};
      engTakeAPi();

      if (mode === "preview") {
        const parentUrl = pageURL || getQueryParam("pageURL");
        const urls = {
          staging:
            "https://bridged-qna-rag-agent-80245669623.us-central1.run.app/answerV2",
          production:
            "https://prod-bridged-qna-rag-agent-fekv67xtza-ew.a.run.app/answerV2",
          local:
            "https://bridged-qna-rag-agent-80245669623.us-central1.run.app/answerV2",
        };

        const apiURL = urls[environment] || urls.production;

        const sentData = {
          question: input || "", // dynamic
          model: "gpt-4o-mini",
          actionBucketId: actionBucketId, // dynamic
          domain: extractDomain(parentUrl) || "", // dynamic
          provider: "openai",
          documents_limit: 10,
          language: "en",
          response_format: "para_answer",
          use_rerank: false,
        };

        const localResponse = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sentData),
        });
        const localData = await localResponse.json();

        response = {
          data: {
            data: {
              ...localData,
            },
          },
        };
      } else {
        response = await _SEND_RAG_QUERY({
          question: input,
        });
      }

      if (isDEAStaticDemo) {
        response = {
          data: {
            data: {
              data: {
                ga4Data: {
                  Metric: [
                    "Read Rate",
                    "Scroll Depth",
                    "Average Time",
                    "Completion rate",
                  ],
                  Value: ["78%", "65%", "2 minutes", "55%"],
                  Description: [
                    "Percentage of users who read the section in full",
                    "Average scroll depth for this section",
                    "Time readers spent on this section on average",
                    "Percentage of readers who completed the section",
                  ],
                },
                ga4Data2: {
                  "User Segment": [
                    "Returning Visitors",
                    "New Visitors",
                    "Mobile Users",
                    "Desktop Users",
                  ],
                  "Read Rate": ["85%", "65%", "60%", "82%"],
                  "Scroll Depth": ["72%", "58%", "52%", "70%"],
                  "Avg Time on Section": [
                    "2.5 minutes",
                    "1.8 minutes",
                    "1.6 minutes",
                    "2.3 minutes",
                  ],
                },
                response:
                  "Let’s dive into the engagement analytics for the selected section of your article. I’ll break down the key metrics to give you an overview of how readers are interacting with this content. Let me show you some tables to make the data easy to follow:",

                // insights: [
                //   {
                //     title:
                //       "United States - High Bounce Rate with Moderate User Engagement",
                //     insight:
                //       "The United States exhibits a high bounce rate of 71.76%, indicating that users often leave the site after viewing only one page. Despite this, the average engagement rate is moderate, suggesting that when users do engage, they find the content somewhat appealing.",
                //     recommendation:
                //       "Enhance the landing pages with more engaging content and clear calls to action to reduce bounce rates. Consider A/B testing different layouts and content strategies to see what resonates best with the US audience.",
                //   },
                //   {
                //     title:
                //       "South Korea and China - High Bounce Rate but Potential for Increased Engagement",
                //     insight:
                //       "Both South Korea and China show bounce rates of 83.33% and 80%, respectively. However, these countries have shown potential for engagement when users are retained beyond the first page.",
                //     recommendation:
                //       "Focus on improving the initial user experience for South Korean and Chinese users. This could involve localising content, optimising load times, and ensuring mobile compatibility. Targeted marketing campaigns that highlight engaging content could also help retain users.",
                //   },
                //   {
                //     title:
                //       "United Kingdom and Spain - Moderate Bounce Rate with Good Engagement Potential",
                //     insight:
                //       "The United Kingdom and Spain have moderate bounce rates of 52.74% and 54.05%, respectively. This suggests that while some users are leaving early, there is a substantial portion that stays and engages with the content.",
                //     recommendation:
                //       "Optimise content for these regions by understanding cultural preferences and popular topics. Invest in personalised content delivery and targeted promotions to increase engagement and reduce bounce rates further.",
                //   },
                //   {
                //     title:
                //       "Kenya - Low Bounce Rate with Opportunity for Conversion",
                //     insight:
                //       "Kenya displays a remarkably low bounce rate of 16.67%, indicating strong initial engagement. There is a significant opportunity to convert these engaged users into leads or customers.",
                //     recommendation:
                //       "Implement conversion-focused strategies for Kenyan users, such as compelling calls to action and tailored offers. Streamline the conversion path by simplifying forms and ensuring a seamless checkout process to maximise the conversion rate.",
                //   },
                // ],
              },
              ragResultId: "123",
            },
          },
        };
      }

      if (response?.data?.data?.data) {
        dispatch(
          setNumberOfKaQuestionsSubmitted(numberOfKaQuestionsSubmitted + 1)
        );
        const sentData = {
          ...response?.data?.data?.data,
          question: input,
          ragResultId: response?.data?.data.ragResultId,
          feedback: {
            isTaken: false,
            rating: 0,
          },
        };

        if (
          engagementTemplate === "three" ||
          engagementTemplate === "inlineFAQ"
        ) {
          setResponseData([...responseData, sentData]);
        } else {
          setResponseData([sentData, ...responseData]);
        }

        setInputQuery("");
        dispatch(setIsRagCollapsed(false));
        if (isRagCollapsed) {
          if (!extraConfig?.isAlreadyExpanded) {
            sendCollapseEvent();
          }
        }
        setIsError({
          isError: false,
          message: "",
        });
      }

      if (response?.error) {
        setIsError({
          isError: true,
          message:
            response?.error?.data?.errorObject?.userErrorText ||
            "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const engTakeAPi = async (_id) => {
    let optionId = "";

    if (options?.length > 0) {
      optionId = options[0]?._id;
    }
    const sentData = {
      engagementId: engagementId,
      optionId: optionId,
    };

    await _ENGAGEMENT_TAKE(sentData);
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  useEffect(() => {
    if (responseData?.length === 1) {
      dispatch(setIsRagFirstQuestion(true));
    }

    if (engagementTemplate === "two") {
      dispatch(setIsRagTwoQuestionAsked(!isRagTwoQuestionAsked));
    }
  }, [responseData, engagementTemplate]);

  useEffect(() => {
    if (numberOfQuestionsSubmittedConfig) {
      dispatch(
        setNumberOfKaQuestionsSubmitted(numberOfQuestionsSubmittedConfig)
      );
    }
  }, [numberOfQuestionsSubmittedConfig]);

  const [_FEEDBACK] = useUserFeedbackMutation();
  const handRatingTake = async (newRating, currentIndex) => {
    const updatedResponseData = [...responseData];
    if (updatedResponseData[currentIndex]) {
      updatedResponseData[currentIndex].feedback = {
        isTaken: true,
        rating: newRating,
      };
      setResponseData(updatedResponseData);
      let sentData = {
        rate: newRating,
        feedbackData: {
          campaignType: campaignType || "",
          ragResultId: updatedResponseData[currentIndex]?.ragResultId || "",
        },
      };
      await _FEEDBACK(sentData);
    }
  };

  useEffect(() => {
    if (engagementTemplate === "two") {
      if (width <= 500) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }
  }, [width, engagementTemplate]);

  const [_CTA_TAKE] = useCtaTakeMutation();
  const handeLinkClick = async (actionId) => {
    if (actionId) {
      await _CTA_TAKE({
        actionId: actionId,
        takeActionData: {
          actionType: "link",
        },
      });
    }
  };

  useEffect(() => {
    if (kaQuestionTrigger) {
      handleGenerateAnswer(kaQuestionTrigger);
      dispatch(setKaQuestionTrigger(""));
    }
  }, [kaQuestionTrigger]);

  useEffect(() => {
    if (showActionAfterResponses === 0) {
      dispatch(setKaGatedTrigger(false));
      return;
    }

    const shouldTriggerGatedAction =
      gatedEngagement &&
      actions?.length > 0 &&
      numberOfKaQuestionsSubmitted === showActionAfterResponses;

    dispatch(setKaGatedTrigger(shouldTriggerGatedAction));
  }, [
    gatedEngagement,
    actions,
    responseData,
    showActionAfterResponses,
    numberOfKaQuestionsSubmitted,
  ]);

  return {
    isLoading,
    inputQuery,
    responseData,
    isRagCollapsed,
    setInputQuery,
    handleGenerateAnswer,
    handRatingTake,
    error,
    isMobileView,
    handeLinkClick,
  };
}

export default useKnowledgeAgentController;
