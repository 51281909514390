import React from "react";
import useKnowledgeAgentController from "../controllers/useKnowledgeAgentController";
import SidebarOneTopBar from "../components/One/SidebarOneTopBar";
import SidebarOneBody from "../components/One/SidebarOneBody";
import Button from "../../../../utils/components/Button";
import Icon from "../../../../utils/components/Icon";
import { useSelector } from "react-redux";

function SidebarOne() {
  const isDEAStaticDemo = useSelector(
    (state) => state?.flipcard?.data?.config?.isDEAStaticDemo
  );
  const {
    responseData,
    handleGenerateAnswer,
    inputQuery,
    setInputQuery,
    isLoading,
    error,
    handeLinkClick,
    handRatingTake,
  } = useKnowledgeAgentController();

  return (
    <div className="kaSidebarOne">
      {isDEAStaticDemo && responseData?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "var(--mpr-2)",
            paddingBottom: "0px",
          }}
        >
          <div>
            <h3 style={{ fontWeight: 400 }}>Welcome back</h3>
            <h2 style={{ fontWeight: 400 }}>Rishabh</h2>
          </div>

          <div>
            <Button>
              <Icon
                name="ri-logout-circle-r-line"
                style={{ fontSize: "15px" }}
              />
            </Button>
          </div>
        </div>
      )}

      <SidebarOneTopBar
        responseData={responseData}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
        error={error}
      />
      <SidebarOneBody
        responseData={responseData}
        handeLinkClick={handeLinkClick}
        handRatingTake={handRatingTake}
      />
    </div>
  );
}

export default SidebarOne;
