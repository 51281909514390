import { useState } from "react";
import { useSelector } from "react-redux";
import { exportDmgPdf } from "../../../../utils/controllers/exportData";

const useDmgController = () => {
  const hostname = useSelector(
    (state) => state.flipcard.data?.config?.hostname
  );
  const [inputQuery, setInputQuery] = useState("");
  const [isBody, setIsBody] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onHandleDmgClick = async (input = inputQuery) => {
    if (!input || isLoading) return;

    setInputQuery(input);
    setIsLoading(true);

    const sentData = {
      hostname: hostname,
      question: input,
    };

    try {
      const response = await fetch(
        "https://dmg-demo-service-80245669623.europe-west1.run.app/generateInsights",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(sentData),
        }
      );

      const data = await response.json();

      if (data) {
        setResponseData((prevData) => [
          { ...data, question: input },
          ...prevData,
        ]);
        setInputQuery("");
        setIsLoading(false);

        if (Notification.permission === "granted") {
          new Notification("Insights created", {
            body: `Your Insights for "${input}" was created.`,
          });
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              new Notification("Insights created", {
                body: `Your Insights for "${input}" was created.`,
              });
            }
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleQueryDownload = (query) => {
    const queryText = query || "No query available";
    const blob = new Blob([queryText], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "query.txt";
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleAnswerPdfDownload = (data) => {
    exportDmgPdf(data);
  };

  return {
    inputQuery,
    setInputQuery,
    isLoading,
    setIsBody,
    isBody,
    onHandleDmgClick,
    responseData,
    handleQueryDownload,
    handleAnswerPdfDownload,
  };
};

export default useDmgController;
