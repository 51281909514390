import React from "react";

function AdvertisementAsset(background) {
  const bg = background;
  const type = bg?.type;
  const color = bg?.color;
  const imageURL = bg?.imageURL;
  const isColor = type === "color";
  const isImage = type === "image";
  return (
    <div
      className="advertisementAsset"
      style={{ backgroundColor: isColor && color }}
    >
      {isImage && <img src={imageURL} />}
    </div>
  );
}

export default AdvertisementAsset;
