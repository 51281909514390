import React, { useEffect, useState } from "react";
import CtaLayout from "../../../layout/CtaLayout";
import { useSelector } from "react-redux";
import ctaFinder from "../../../constants/ctas/ctaFinder";

function CtaWorkflow({ actionIndex, isTransparent }) {
  const [ctaType, setCtaType] = useState("");
  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);

  useEffect(() => {
    if (ctas?.length > 0) {
      setCtaType(ctas[actionIndex]?.actionData?.actionType);
    }
  }, [ctas, actionIndex]);

  const renderCtaSection = () => {
    if (!ctaType) {
      // Handle case where CTA type is not found
      return <div>No CTA found for the specified action index</div>;
    }

    const ctaTypeObject = ctaFinder(ctaType);
    if (!ctaTypeObject) {
      // Handle case where CTA type object is not found
      return <div>Invalid CTA type: {ctaType}</div>;
    }

    const Component = ctaTypeObject.component;
    return <Component data={ctas[actionIndex]} />;
  };

  if (isTransparent) {
    return renderCtaSection();
  }

  const backgroundStyle = ctas?.[actionIndex]?.background || {};
  return (
    <CtaLayout background={backgroundStyle}>{renderCtaSection()}</CtaLayout>
  );
}

export default CtaWorkflow;
