import React from "react";
import { useSelector } from "react-redux";
import useThemeHandler from "../utils/controllers/useThemeHandler";

function FlipcardLayout({ children }) {
  useThemeHandler();
  const isDarkTheme = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme
  );

  return (
    <div className={`flipcardLayout ${isDarkTheme ? "darkTheme" : ""}`}>
      {children}
    </div>
  );
}

export default FlipcardLayout;
