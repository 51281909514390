import React, { useEffect } from "react";
import "./styles/cta.css";
import Message from "../utils/presentation/message/workflow/Message";
import { useDispatch, useSelector } from "react-redux";
import { setCtaTextColor } from "../redux/slices/appSlice";
import getTextColor from "../utils/controllers/getTextColor";

function CtaLayout({ children, background }) {
  const outerBorderRadius = useSelector((state) => state.app.outerBorderRadius);
  const dispatch = useDispatch();
  const type = background?.type;
  const color = background?.color;
  const imageURL = background?.imageURL;
  const isColor = type === "color";
  const isImage = type === "image";

  useEffect(() => {
    const calculateTextColor = async () => {
      const textColor = isColor ? getTextColor(color) : "white";
      dispatch(setCtaTextColor(textColor));
    };

    if (background) {
      calculateTextColor();
    }
  }, [background, dispatch, isColor, color]);

  return (
    <div className="bridgedCard" style={{ borderRadius: outerBorderRadius }}>
      <div
        className="cta"
        style={{
          backgroundColor: isColor && color,
          backgroundImage: isImage && `url(${imageURL})`,
        }}
      >
        <div
          className="ctaBody"
          style={{
            backgroundColor: isImage && "var(--overlay)",
          }}
        >
          {children}
          <Message />
        </div>
      </div>
    </div>
  );
}

export default CtaLayout;
