import React from "react";
import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings";

function KARatings({ data, handRatingTake, index }) {
  const feedbackConfig = useSelector(
    (state) => state.flipcard.data.config?.feedbackConfig
  );

  if (feedbackConfig?.isActive) {
    return (
      <div className="kaRatingsParent">
        <div className="kaRatingsOne">
          <p>{feedbackConfig?.title || "Rate your experience"}</p>

          <StarRatings
            rating={data?.rating}
            starRatedColor="#ffa106"
            changeRating={(e) => {
              if (!data?.isTaken) {
                handRatingTake(e, index);
              }
            }}
            numberOfStars={5}
            name="rating"
            starDimension="15px"
            starSpacing="1px"
            starHoverColor="#ffa106"
          />
        </div>
      </div>
    );
  }

  return <></>;
}

export default KARatings;
