import React from "react";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";
import getTextColor from "../../../../../utils/controllers/getTextColor";
import KAReference from "./KAReference";
import ChatLoader from "../../../../../utils/presentation/chatLoader/workflow/ChatLoader";

function KAAnswerChatBot({ data, responseAnswer, handeLinkClick, isBlur }) {
  const primaryColor = useSelector(
    (state) => state.flipcard.data.flipCard.theme.primaryColor
  );

  const textColor = getTextColor(primaryColor) || "white";

  const blurStyle = isBlur
    ? {
        filter: "blur(4px)",
        pointerEvents: "none",
        userSelect: "none",
      }
    : {};

  return (
    <div className="kaAnswers">
      <div
        className="kaAnswersQuestion"
        style={{ backgroundColor: "var(--primary-Color)", color: textColor }}
      >
        <h3>{data?.question}</h3>
      </div>
      {data?.isLoading ? (
        <div style={{ marginBottom: "20px" }}>
          <ChatLoader />
        </div>
      ) : (
        <div className="kaAnswersAnswer" style={blurStyle}>
          <Markdown className="kaAnswerMainDiv">{responseAnswer}</Markdown>
          <KAReference data={data?.reference} handeLinkClick={handeLinkClick} />
        </div>
      )}
    </div>
  );
}

export default KAAnswerChatBot;
