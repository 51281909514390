import React from "react";
import { useSelector } from "react-redux";
import KALogo from "../KALogo";
import CollapseIcon from "../CollapseIcon";
import KALoader from "../KALoader";
import TwoInput from "./TwoInput";

function TwoTopBar({
  responseData,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
  isLoading,
  error,
}) {
  const title = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.title
  );
  return (
    <div className="kaTopBarOne kaTopBarTwo">
      <KALogo />
      <CollapseIcon responseData={responseData} />
      <h3>{title || "Ask a question related to article."}</h3>
      <KALoader
        isLoading={isLoading}
        responseData={responseData}
        error={error}
        loaderColor="#1E2B46"
      />
      <TwoInput
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
      />
    </div>
  );
}

export default TwoTopBar;
