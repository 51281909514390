import React from "react";
import useGetWindowWidth from "../../../../utils/controllers/useGetWindowWidth";
import AdsWrapper from "../components/AdsWrapper";
import { useSelector } from "react-redux";
import AdManager from "../components/AdManager";

function GoogleAdsWorkflow() {
  const width = useGetWindowWidth();
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const isGoogleAdManagerAd = useSelector(
    (state) => state.flipcard.data?.config?.googleAdConfig?.isGoogleAdManagerAd
  );

  if (width > 0 && !isXrayCollapsed) {
    if (isGoogleAdManagerAd) {
      return <AdManager />;
    }
    return <AdsWrapper />;
  } else {
    <div />;
  }
}

export default GoogleAdsWorkflow;
