import React from "react";
import Input from "../../../../utils/components/Input";
import { useSelector } from "react-redux";
import Button from "../../../../utils/components/Button";

function DmgInputBox({
  inputQuery,
  setInputQuery,
  isLoading,
  onHandleDmgClick,
}) {
  const { generateText, enterQuestion } = useSelector(
    (state) => state.language.data
  );

  return (
    <form
      className="DmgInputBox"
      onSubmit={(e) => {
        e.preventDefault();
        onHandleDmgClick();
      }}
    >
      <Input
        placeholder={enterQuestion}
        value={inputQuery}
        onChange={(e) => {
          setInputQuery(e.target.value);
        }}
      />

      <Button isLoading={isLoading} type="submit">
        {generateText}
      </Button>
    </form>
  );
}

export default DmgInputBox;
