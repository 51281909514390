import React from "react";
import OneTopBar from "../components/One/OneTopBar";
import OneBody from "../components/One/OneBody";
import useKnowledgeAgentController from "../controllers/useKnowledgeAgentController";

function One() {
  const {
    responseData,
    handleGenerateAnswer,
    inputQuery,
    setInputQuery,
    isLoading,
    error,
    handeLinkClick,
    handRatingTake,
  } = useKnowledgeAgentController();

  return (
    <div className="kaOne">
      <OneTopBar
        responseData={responseData}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
        error={error}
      />
      <OneBody
        responseData={responseData}
        handleGenerateAnswer={handleGenerateAnswer}
        handeLinkClick={handeLinkClick}
        handRatingTake={handRatingTake}
      />
    </div>
  );
}

export default One;
