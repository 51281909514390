import Front from "./Front";
import Back from "./Back";
import { useSelector } from "react-redux";
import "../style/flipcard.css";
import FlippableCard from "./FlippableCard";
import { GoogleOAuthProvider } from "@react-oauth/google";

function Flipcard() {
  const googleSigninRequired = useSelector(
    (state) => state?.flipcard?.data?.config?.googleSigninRequired
  );

  if (googleSigninRequired) {
    return (
      <GoogleOAuthProvider clientId="80245669623-9cofo9df01cf78t640u1shihiogclve9.apps.googleusercontent.com">
        <FlippableCard>
          <Front />
          <Back />
        </FlippableCard>
      </GoogleOAuthProvider>
    );
  }

  return (
    <FlippableCard>
      <Front />
      <Back />
    </FlippableCard>
  );
}

export default Flipcard;
