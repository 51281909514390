import "../styles/xray.css";
import { useSelector } from "react-redux";
import Two from "../templates/two/Two";
import One from "../templates/one/One";

function XRayWorkflow() {
  const engagementTemplate = useSelector(
    (state) =>
      state.flipcard.data?.flipCard?.engagement?.engagementData
        ?.engagementTemplate
  );

  if (engagementTemplate === "two") {
    return <Two />;
  } else {
    return <One />;
  }
}

export default XRayWorkflow;
