import React from "react";
import "../styles/message.css";
import useMessage from "../controllers/useMessage";

const Message = () => {
  const { isVisible, messageData } = useMessage();
  const { type, message } = messageData;

  return (
    <div className={`message ${isVisible ? "visible" : "hidden"}`}>
      <div className={`messageContent ${type}`}>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Message;
