import React from "react";
import Button from "../../../../utils/components/Button";
import Icon from "../../../../utils/components/Icon";

function DmgUser() {
  return (
    <div className="dmgUser">
      <div>
        <h3>Welcome back</h3>
        <h2>Rishabh</h2>
      </div>

      <div>
        <Button>
          <Icon name="ri-logout-circle-r-line" style={{ fontSize: "15px" }} />
        </Button>
      </div>
    </div>
  );
}

export default DmgUser;
