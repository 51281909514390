import React from "react";
import FlipIcon from "./FlipIcon";
import { useSelector } from "react-redux";
import CtaBackButton from "./CtaBackButton";

function CtaFlipIcon({ color }) {
  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);
  const isFlipped = useSelector((state) => state.app.isFlipped);

  const isGatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const isEngagement = useSelector(
    (state) => state.flipcard?.data?.flipCard?.engagement?._id
  );

  if (ctas?.length > 1 && isFlipped && !isGatedEngagement) {
    return <CtaBackButton />;
  }

  if (isEngagement && !isGatedEngagement && isFlipped) {
    return <FlipIcon color={color} />;
  }

  return <div />;
}

export default CtaFlipIcon;
