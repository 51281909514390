import React from "react";
import { useSelector } from "react-redux";

function Body({ cta }) {
  const showDescription = useSelector(
    (state) =>
      state?.flipcard?.data?.flipCard?.ctaPreviewConfig?.showDescription
  );

  // const readMore_TEXT = useSelector(
  //   (state) => state.language.data.readMore_TEXT
  // );
  // const buttonText = actionData?.buttonText;

  return (
    <div className="advertisementContent">
      {showDescription ? (
        <>
          <h3 className="firstLine">{cta?.title}</h3>
          <p className="secondLine">{cta?.description}</p>
        </>
      ) : (
        <>
          <h3>{cta?.title}</h3>
        </>
      )}

      {/* <p className="primaryTextColor" style={{ fontWeight: 500 }}>
        {buttonText || readMore_TEXT}
      </p> */}
    </div>
  );
}

export default Body;
