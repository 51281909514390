import React from "react";

function DmgTable({ ga4Data, columns, tableData }) {
  if (!ga4Data) {
    return <></>;
  }

  return (
    <table
      style={{ width: "100%", borderCollapse: "collapse" }}
      className="DmgTable"
    >
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.key}
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f2f2f2",
              }}
            >
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row) => (
          <tr key={row.key}>
            {columns.map((column) => (
              <td
                key={column.key}
                style={{ border: "1px solid #ddd", padding: "8px" }}
              >
                {column.dataIndex === "url" ? (
                  <a
                    href={`https://${row[column.dataIndex]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row[column.dataIndex]}
                  </a>
                ) : (
                  row[column.dataIndex]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DmgTable;
