import React from "react";
import { useSelector } from "react-redux";

function Quotes({ data }) {
  const language = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.language
  );
  return (
    <div className="xrayQuotes">
      {data?.quotes?.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              textAlign: language === "he" ? "right" : "left",
            }}
            className="xrayQuote"
          >
            <h3>{item?.speaker}</h3>
            <p>"{item?.quote}"</p>
          </div>
        );
      })}
    </div>
  );
}

export default Quotes;
