import React from "react";
import FlipIcon from "./FlipIcon";
import { useSelector } from "react-redux";

function EngFlipIcon() {
  const ctas = useSelector((state) => state.flipcard?.data?.flipCard?.actions);
  const isEndScreen = useSelector(
    (state) => state.flipcard?.data?.flipCard?.endScreen?._id
  );

  if (ctas?.length > 0 || isEndScreen) {
    return <FlipIcon />;
  }

  return <></>;
}

export default EngFlipIcon;
