import React, { cloneElement } from "react";
import useGetTitleHeight from "../controllers/useGetTitleHeight";

function TitleLeftBorder({ children, style, isError, left }) {
  const [titleRef, titleHeight] = useGetTitleHeight();

  // Clone the single child and assign titleRef as a ref
  const childWithRef = cloneElement(children, { ref: titleRef });

  return (
    <>
      <span
        className="cardLeftTitleBorder"
        style={{
          height: titleHeight,
          ...style,
          borderColor: isError && "#d24847",
          left: left && left,
        }}
      ></span>
      {childWithRef}
    </>
  );
}

export default TitleLeftBorder;
