import React from "react";
import AdsComponent from "./AdsComponent";
import useLoadAdSenseScript from "../controllers/useLoadAdSenseScript";
import { useSelector } from "react-redux";

function AdsWrapper() {
  const googleAdConfig = useSelector(
    (state) => state.flipcard.data?.config?.googleAdConfig
  );

  const adClient = googleAdConfig?.adClient;
  const adSlot = googleAdConfig?.adSlot;

  useLoadAdSenseScript(adClient);
  return <AdsComponent dataAdSlot={adSlot} dataAdClient={adClient} />;
}

export default AdsWrapper;
