import React from "react";
import "../styles/gateway.css";
import TopBar from "../../reusable/components/TopBar";
import Body from "../components/Body";

function GatewayWorkflow() {
  return (
    <div className="gateway">
      <TopBar isHideAnalytics={true} />
      <Body />
    </div>
  );
}

export default GatewayWorkflow;
