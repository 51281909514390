import React from "react";
import "./scrollAnimation.css";

function ScrollAnimation({ isVisible, style }) {
  if (!isVisible) {
    return <></>;
  }

  return (
    <div className="scrollAnimation" style={{ ...style }}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default ScrollAnimation;
