import React from "react";
import { useSelector } from "react-redux";

function KAFooter() {
  const footer = useSelector((state) => state.flipcard.data.config?.footer);

  if (footer?.isActive) {
    return (
      <div className="kaFooter">
        <h5
          dangerouslySetInnerHTML={{
            __html: footer?.title,
          }}
        />
      </div>
    );
  }

  return <></>;
}

export default KAFooter;
