import React from "react";

function Divider({ style, type }) {
  return (
    <div
      className="divider"
      style={{
        ...style,
        background: type === "primary" && "var(--primary-Color)",
      }}
    ></div>
  );
}

export default Divider;
