import React from "react";
import { useSelector } from "react-redux";

function FAQ({ onHandleDmgClick }) {
  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  return (
    <div className="dmgFAQs">
      {questions?.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              onHandleDmgClick(item);
            }}
            className="dmgFAQ"
          >
            <h5>{item}</h5>
          </div>
        );
      })}
    </div>
  );
}

export default FAQ;
