import { useState } from "react";
import { useUserFeedbackMutation } from "../../../../../services/api";
import { useSelector } from "react-redux";

const useFeedbackHandler = (ragResultId) => {
  const campaignType = useSelector(
    (state) => state?.flipcard?.data?.config?.campaignType
  );
  const [rating, setRating] = useState(0);
  const [isAlreadyTaken, setIsAlreadyTaken] = useState(false);

  const [_FEEDBACK] = useUserFeedbackMutation();

  const handRatingTake = async (newRating) => {
    let sentData = {
      rate: newRating,
      feedbackData: {
        campaignType: campaignType || "",
        ...(ragResultId && {
          ragResultId: ragResultId,
        }),
      },
    };

    await _FEEDBACK(sentData);
  };

  const changeRating = async (newRating) => {
    if (isAlreadyTaken) {
      return;
    }

    setIsAlreadyTaken(true);
    setRating(newRating);

    handRatingTake(newRating);
  };

  return {
    rating,
    changeRating,
  };
};

export default useFeedbackHandler;
