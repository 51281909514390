import React, { useEffect } from "react";
import "../style/CtaCarouselWorkflow.css";
import TopBar from "../components/TopBar";
import Content from "../components/Content";
import CtaWorkflow from "../../../../presentation/ctas/workflow/CtaWorkflow";
import { useSelector } from "react-redux";
import { useCollectEventMutation } from "../../../../services/api";

function CtaCarouselWorkflow() {
  const outerBorderRadius = useSelector((state) => state.app.outerBorderRadius);
  // Select necessary data from Redux store
  const crouselCurrentCta = useSelector((state) => state.app.crouselCurrentCta);
  const [_CAROUSEL] = useCollectEventMutation();

  // Send carousel view event on component mount
  useEffect(() => {
    const handleViewCarousel = async () => {
      await _CAROUSEL({
        eventData: {
          eventType: "carouselView",
        },
      });
    };

    handleViewCarousel();
  }, [_CAROUSEL]);

  return (
    <div className="bridgedCard" style={{ borderRadius: outerBorderRadius }}>
      {typeof crouselCurrentCta === "number" && (
        <CtaWorkflow actionIndex={crouselCurrentCta} />
      )}
      <div className="ctaCarousel">
        <div className="ctaCarouselBody">
          <TopBar />
          <Content />
        </div>
      </div>
    </div>
  );
}

export default CtaCarouselWorkflow;
