import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../../utils/components/Icon";
import usePostMessage from "../../../../../utils/controllers/usePostMessage";
import useEngController from "../../../reusable/controllers/useEngController";
import { setIsXrayCollapsed } from "../../../../../redux/slices/appSlice";
import getTextColor from "../../../../../utils/controllers/getTextColor";
import { getColorOpacityPercentage } from "../../../../../utils/controllers/colors";

function XRayTopBar({
  currentEngData,
  totalEngagements,
  handlePopEngagementData,
}) {
  const darkTheme = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme
  );

  const dispatch = useDispatch();
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const headingText = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.title
  );

  const primaryColor = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.primaryColor
  );

  const { handleXraytake } = useEngController();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const { postMessage } = usePostMessage();

  const backIcon = () => {
    return (
      <Icon
        name="ri-arrow-right-s-line"
        style={{
          cursor: "pointer",
        }}
        onClick={handlePopEngagementData}
      />
    );
  };

  const onCollapseHandler = (data) => {
    dispatch(setIsXrayCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  const closeIcon = () => {
    if (isXrayCollapsed) {
      return (
        <Icon
          name="ri-add-line"
          onClick={() => {
            onCollapseHandler(false);
          }}
          style={{
            cursor: "pointer",
          }}
        />
      );
    } else {
      return (
        <Icon
          name="ri-close-line"
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (!isXrayCollapsed) {
      handleXraytake(currentEngData?._id);
    }

    const bridgedCardElement = document.querySelector(".bridgedCard");
    if (bridgedCardElement) {
      bridgedCardElement.style.height = isXrayCollapsed ? "37px" : "100%";
    }
  }, [isXrayCollapsed]);

  return (
    <div
      style={{
        cursor: isXrayCollapsed && "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
      onClick={() => {
        if (isXrayCollapsed) {
          onCollapseHandler(false);
          sendCollapseEvent();
        }
      }}
    >
      <div
        style={{
          backgroundColor: primaryColor,
          color: getTextColor(primaryColor),
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <h3
          style={{
            padding: "var(--mpr-mini) var(--mpr-3)",
            paddingRight: "3px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="firstLine">
            {isXrayCollapsed
              ? headingText || currentEngData?.questionText
              : currentEngData?.questionText}{" "}
            <span
              style={{
                fontWeight: "500",
                fontSize: "14px",
                marginLeft: "2px",
              }}
            >
              AI
            </span>
          </span>

          <img style={{ height: "22px" }} src="/ai_animation.gif" alt="Stars" />

          {totalEngagements > 1 && !isXrayCollapsed && backIcon()}
        </h3>

        <div
          style={{
            borderLeft: `2px solid ${darkTheme ? "#2E2E2E" : "#F5F5F5"}`,
            padding: "var(--mpr-mini)",
          }}
        >
          {closeIcon()}
        </div>
      </div>
    </div>
  );
}

export default XRayTopBar;
