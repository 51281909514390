import React from "react";
import useKnowledgeAgentController from "../controllers/useKnowledgeAgentController";
import InlineFAQTopBar from "../components/One/InlineFAQTopBar";
import InlineFAQBody from "../components/One/InlineFAQBody";

function InlineFAQ() {
  const {
    responseData,
    handleGenerateAnswer,
    inputQuery,
    isLoading,
    handeLinkClick,
    setInputQuery,
    handRatingTake,
  } = useKnowledgeAgentController();

  return (
    <div className="kaInlineFAQ">
      <InlineFAQTopBar
        handleGenerateAnswer={handleGenerateAnswer}
        responseData={responseData}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
      />
      <InlineFAQBody
        responseData={responseData}
        handleGenerateAnswer={handleGenerateAnswer}
        handeLinkClick={handeLinkClick}
        isLoading={isLoading}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        handRatingTake={handRatingTake}
      />
    </div>
  );
}

export default InlineFAQ;
