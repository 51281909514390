import React from "react";
import "./styles/eng.css";
import Message from "../utils/presentation/message/workflow/Message";
import { useSelector } from "react-redux";

function EngLayout({ children }) {
  const outerBorderRadius = useSelector((state) => state.app.outerBorderRadius);
  const engagementTemplate = useSelector(
    (state) =>
      state.flipcard.data?.flipCard?.engagement?.engagementData
        ?.engagementTemplate
  );

  const injectionStyle = useSelector(
    (state) => state.flipcard.data.config.injectionStyle
  );

  const border = () => {
    if (engagementTemplate === "two" || injectionStyle === "sidePanel") {
      return "none";
    }

    return "1px solid var(--fill-Color)";
  };

  return (
    <div
      className="bridgedCard"
      style={{
        borderRadius: outerBorderRadius,
        border: border(),
      }}
    >
      <div
        className="eng"
        style={{
          backgroundColor:
            engagementTemplate === "two"
              ? "transparent"
              : "var(--background-Color)",
        }}
      >
        <div className="engBody">
          {children}
          <Message />
        </div>
      </div>
    </div>
  );
}

export default EngLayout;
