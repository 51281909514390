import React from "react";
import "./styles/endScreen.css";
import Message from "../utils/presentation/message/workflow/Message";
import { useSelector } from "react-redux";

function EndScreenLayout({ children }) {
  const outerBorderRadius = useSelector((state) => state.app.outerBorderRadius);
  return (
    <div className="bridgedCard" style={{ borderRadius: outerBorderRadius }}>
      <div className="endScreen">
        <div className="endScreenBody">
          {children}
          <Message />
        </div>
      </div>
    </div>
  );
}

export default EndScreenLayout;
