import React from "react";
import KaDeaTable from "./KaDeaTable";
import KaInsights from "./KaInsights";
import { useSelector } from "react-redux";
import Button from "../../../../../utils/components/Button";
import Icon from "../../../../../utils/components/Icon";

function getTableData(input) {
  if (!input || Object.keys(input).length === 0) {
    return { columns: [], tableData: [] };
  }

  const columns = Object.keys(input).map((key) => ({
    title: key,
    dataIndex: key,
    key: key,
  }));

  const tableData = input[columns[0].dataIndex].map((_, index) => {
    const row = { key: index + 1 };
    columns.forEach((column) => {
      row[column.dataIndex] = input[column.dataIndex][index];
    });
    return row;
  });

  return { columns, tableData };
}

function KADEA({ data }) {
  const isDEAStaticDemo = useSelector(
    (state) => state?.flipcard?.data?.config?.isDEAStaticDemo
  );

  const { columns, tableData } = getTableData(data?.ga4Data);
  const { columns: columns2, tableData: tableData2 } = getTableData(
    data?.ga4Data2
  );

  if (!data?.ga4Data) {
    return <></>;
  }

  return (
    <div className="kaDEA">
      {isDEAStaticDemo && (
        <h3 style={{ fontWeight: "800" }}>
          Table 1: Reader Engagement Metrics
        </h3>
      )}
      {tableData?.length > 0 && (
        <KaDeaTable
          ga4Data={data?.ga4Data}
          columns={columns}
          tableData={tableData}
        />
      )}

      <KaInsights insights={data?.insights} />

      {isDEAStaticDemo && (
        <>
          <h3>
            This first table gives us a quick look at how engaged readers are
            with the section. The read rate shows that a significant portion of
            users are reading through to the end of this section, which is
            promising. However, the completion rate being slightly lower
            indicates that while readers are mostly sticking with the section,
            some may lose interest toward the end.
          </h3>
          <h3 style={{ fontWeight: "800" }}>
            Table 2: Engagement by User Segments
          </h3>
          <KaDeaTable
            ga4Data={data?.ga4Data2}
            columns={columns2}
            tableData={tableData2}
          />
          <h3>
            This breakdown shows us how different types of readers are engaging
            with the content. Returning visitors are significantly more engaged,
            suggesting they may find the section more relevant or have more
            familiarity with the topic. Desktop users also tend to read more
            deeply, perhaps because it’s easier to engage with long-form content
            on a larger screen. Summary Insights
          </h3>
          <h3>
            The analytics suggest that this section resonates well with
            returning readers and desktop users, who both show higher engagement
            levels. To boost engagement among new visitors, consider adding a
            quick introductory summary or making the section’s main points more
            visually engaging, especially for mobile users.
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <Button>
                Download
                <Icon
                  name="ri-download-fill"
                  style={{ fontSize: "15px", marginLeft: "5px" }}
                />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default KADEA;
