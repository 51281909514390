import React, { useEffect, useState } from "react";
import useCtaViewHandler from "../../reusable/controllers/useCtaViewHandler";
import "../styles/dynamicForm.css";
import Content from "../components/Content";
import { useSelector } from "react-redux";
import Body from "../components/Body";

function DynamicFormWorkflow({ data }) {
  const { handleCtaView } = useCtaViewHandler();
  useEffect(() => {
    handleCtaView(data?._id);
  }, [data]);

  const logo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );
  const [toggleDisplay, setToggleDisplay] = useState(false);
  const handleToggleDisplay = (data) => {
    setToggleDisplay(data);
  };
  return (
    <div className="forms">
      <Content
        handleToggleDisplay={handleToggleDisplay}
        data={data}
        logo={logo}
      />
      <Body
        toggleDisplay={toggleDisplay}
        handleToggleDisplay={handleToggleDisplay}
        data={data}
      />
    </div>
  );
}

export default DynamicFormWorkflow;
