import { useSelector } from "react-redux";
import GoogleLogin from "../../../../../utils/presentation/googleLogin/workflow/GoogleLogin";
import CtaFlipIcon from "../../../../../utils/components/CtaFlipIcon";
import useRegistrationController from "../../controllers/useRegistrationController";

function Centered({ data }) {
  const language = useSelector((state) => state.language.data);
  const { termsAndConditions_TEXT } = language;
  const { main_TEXT, service_TEXT, privacy_TEXT } = termsAndConditions_TEXT;
  const textColor = useSelector((state) => state.app.ctaTextColor);

  const { handleSubmitRegistration, isLoading } = useRegistrationController();
  const logo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );

  const { title, description } = data;

  const submitData = (passedData) => {
    handleSubmitRegistration({
      actionId: data?._id,
      takeActionData: {
        actionType: "registration",
        ...passedData,
      },
    });
  };

  return (
    <div className="registrationCenter">
      <CtaFlipIcon color={textColor} />

      <div
        className="registrationContent registrationCenterContent"
        style={{
          color: textColor,
        }}
      >
        {logo && <img src={logo} alt="Logo" />}

        <h3>{title}</h3>
        <p
          style={{
            backgroundColor: textColor === "white" ? "#00000080" : "#ffffff80",
          }}
          className="description"
        >
          {description}
        </p>

        <GoogleLogin
          onSubmitHandler={submitData}
          loading={isLoading}
          isReturnAccessToken={true}
        />
        <h6
          style={{
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {main_TEXT}{" "}
          <a
            href="https://www.bridged.media/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            {service_TEXT}
          </a>{" "}
          &nbsp; & &nbsp;{" "}
          <a
            href="https://www.bridged.media/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {privacy_TEXT}
          </a>
        </h6>
      </div>
    </div>
  );
}

export default Centered;
